.AdminContentContainer {
  height: calc(98vh - 224px);
}

.AdminTableContainer {
  height: calc(100% - 106px);
}

.QuickChangeSettingsContainer{
  justify-content: flex-start;
}
@media (max-width: 650px) {
  .QuickChangeSettingsContainer {
    justify-content: center;
  }
}
.custom-datagrid-comment-cell {
  white-space: normal !important;
  overflow-y: auto !important;
  line-height: 1.5em !important;
  padding: 10px !important;
}